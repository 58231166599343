import "core-js/stable";
import "regenerator-runtime/runtime";

import 'stylesheets/slough/application.scss'

import 'javascripts/cms_pages'
import 'javascripts/bookings'
import 'javascripts/next_events'
import 'javascripts/events'
import 'javascripts/favourites'
import 'javascripts/organisations'
import 'javascripts/organisation_finder'
import 'javascripts/weather'
import 'javascripts/navbar'
import 'javascripts/documents'
import 'javascripts/blog_article_browser'
import 'javascripts/site_searches'
import 'javascripts/was_this_useful'
import 'javascripts/glide'

import ScrollPosStyler from 'scrollpos-styler';

function ready(callback){
  // in case the document is already rendered
  if (document.readyState!='loading') callback();
  // modern browsers
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
  // IE <= 8
  else document.attachEvent('onreadystatechange', function(){
      if (document.readyState=='complete') callback();
  });
}

ready(function(){

  // Print Page
  var print = document.getElementById("print-button");
  print.addEventListener('click', function () {
    window.print()
  });
})

ready(function(){
  MicroModal.init({
    onClose: (modal, activeElement, event) => {
      event.preventDefault();
      event.stopPropagation();
    }
  });
})
